import { post, get, getBaseURL } from '@kit/utils/APIV1.js'
import { dateToYMD } from '@kit/utils/Formats'
import { downloadFile } from '@kit/utils/APIV1.js'
import { refundEventMock } from '@project/api/refundEvent'


//https://localhost/cms-service/index.php/wp-json/v1/home
// export const createPaymentIntent = async(productID, numUnits, metadata) => {
//   return post(`create-payment-intent`, { 'product_id':productID, 'num_units':numUnits, metadata })
// }
export const createPaymentIntent = async(startDateYMD, endDateYMD, lotType) => {
  return post(`create-payment-intent`, { 'lot_type':lotType, 'start_date':startDateYMD, 'end_date':endDateYMD })
}

export const product = async(slug) => {
  return get(`get-product/slug/${slug}`)
}

export const products = async() => {
  return get('get-products')
}

export const lots = async() => {
  return get('get-lots')
}

export const lot = async(id) => {
  return get(`get-lot-details/id/${id}`)
}

export const testSomeStuff = async() => {
  return get('test-some-stuff')
}

export const testRefundEndPoint = async() => {
  return post('stripe-refund-issued-wh-JF84FJDQ34854HC3', {"__TEST_DATA":refundEventMock })
}

export const downloadReceiptWithPaymentIntentID = async(piid) => {
  return await downloadFile(getBaseURL(`get-receipt/piid/${piid}`), `CCHD-RV-Rental_${piid}.pdf`)
}

export const getAvailability = async(startDate, endDate, lotType, tryHard) => {

  const sDateYYYYMMDD = typeof startDate == "object" ? dateToYMD(startDate) : startDate
  const eDateYYYYMMDD = typeof endDate == "object" ? dateToYMD(endDate) : endDate

  return get(`get-availability/try-hard/${tryHard}/lot-type/${lotType}/start/${sDateYYYYMMDD}/end/${eDateYYYYMMDD}`)

}


export const getBookings = async(stripeID) => {
  return get(`get-bookings/id/${stripeID}`)
}