export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "root" */'@pages/Home.vue'),
    meta: {
      navColors: {
        navText: "white-green",
        navOptions: "deep-grey-green"
      }
    }
  },

  {
    name: 'pay',
    path: '/availability/:id',
    component: () => import(/* webpackChunkName: "root" */'@pages/Availability.vue'),
    meta: {
      navColors: {
        navText: "white-green",
        navOptions: "deep-grey-green"
      }
    }
  }



]