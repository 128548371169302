import { ButtonAlignCenter, ButtonFlashRadial, ButtonIconActiveWidthHeight, ButtonHoverAnimate, ButtonIconPositionShiftLeft, ButtonIconPositionHugLeft, ButtonIconActiveHeight, ButtonIconPassiveHeight } from '@kit/components/IconButton.vue'


  export const blueButton = {
    foreground: {
      active:   "#FFFFFF",
      inactive: "#FFFFFF",
      flash:    "#FFFFFF",
      loading:  "#FFFFFF" 
    },
    background: {
      active:       "#173d6f",
      inactive:     "#CCCCCC",
      flash:        "#4683d4", 
      loading:      "#CCCCCC",
      hover:        "#3061a1",  
      borderRadius: "3px",   
      padding:      "10px"     
    },
    hover: ButtonHoverAnimate,
    iconPosition: ButtonIconPositionShiftLeft,
    align: ButtonAlignCenter,
    flash: ButtonFlashRadial,
    allowPropagate: false,
    loader: {
      fill:"#CCCCCC", 
      wheel: {
        gif:"Spinner-0.4s-310px-ffffff-transparent",
        spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
      }
    }
  }

  export const selectButton = {
    ...blueButton,
    background: {
      ...blueButton.background, 
      padding:"4px 15px 4px 15px"
    }
  }
  
  export const backToCalendarButton = {
    ...blueButton,
    background: {
      ...blueButton.background, 
      padding:"20px 20px 20px 20px"
    }
  }

  export const roundCloseButtonBlue = {
    ...blueButton, 

    background: {
      active:       "#173d6f",
      inactive:     "#CCCCCC",
      flash:        "#4683d4", 
      loading:      "#CCCCCC",
      hover:        "#3061a1",  
      borderRadius: "3px",   
      padding:      "10px 30px 10px 30px"     
    },

    icon:"rounded-close-x_50", 
    iconPosition: ButtonIconPositionHugLeft,
    iconDimension: {
      style: ButtonIconActiveWidthHeight,
      active: ["20px","20px"]
    },
  }

  export const roundCloseButtonRed = {
    ...blueButton, 

    background: {
      active:       "#d42b1c",
      inactive:     "#CCCCCC",
      flash:        "#ff3b4e", 
      loading:      "#CCCCCC",
      hover:        "#f12715",  
      borderRadius: "3px",   
      padding:      "10px 30px 10px 30px"     
    },

    icon:"rounded-close-x_50", 
    iconPosition: ButtonIconPositionHugLeft,
    iconDimension: {
      style: ButtonIconActiveWidthHeight,
      active: ["20px","20px"]
    },
  }

  export const confirmationButton = {
    ...blueButton,
    iconPosition: ButtonIconPositionHugLeft,
    iconDimension: {
      style: ButtonIconActiveWidthHeight,
      active: ["27px","27px"]
    },
  }

  export const footerButton = {
    ...blueButton,
    background: {
      active:       "#405e3c",  
      inactive:     "#CCCCCC",
      flash:        "#6ab04a", 
      loading:      "#CCCCCC",
      hover:        "#2b4727",  
      borderRadius: "10px",   
      padding:      "10px"     
    }, 
    iconFromProject:true, 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    }
  }

  export const formSubmitButton = {
    ...blueButton, 
    iconFromProject:false,
    icon:"rounded-check_30" ,
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    }
  }


  export const navMainSearchButton = { 
    ...blueButton, 
    icon:"rounded-magnifying-glass",
    iconStyle:"transform:rotate(45deg)",
    outerClasses:"sb sb-explicit sths-nav-main-search-iconbutton",
    background: {  
      none: true,
      padding:      "10px", 
      borderRadius: "0px"    
    },
    foreground: "#555555", 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '30px'
    },
    //foreground: "#acdd8c",
    // iconDimension: {
    //   style: ButtonIconActiveHeight,
    //   active: '30px'
    // },
  }

  export const navSearchWidgetButton = { 
    ...blueButton, 
    icon:"rounded-magnifying-glass",
    iconStyle:"transform:rotate(45deg)",
    outerClasses:"sb-explicit sths-nav-main-search-widget-button",
    foreground: "#FFFFFF",
    background: {  
      ...blueButton.background,
      borderRadius: "4px",  
      padding:      "5px",   
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '20px'
    },
  }

  export const prevNextSearchResultsButton = { 
    ...blueButton,
    background: { 
      ...blueButton.background, 
      padding:"7px 20px 7px 20px",
      borderRadius:"3px"
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '25px'  
    } 
  }
  

  export const socialMediaButton = {
    foreground: "#163E6F",
    background: {
      active:       "#849CB3",
      inactive:     "#CCCCCC",
      flash:        "#42b6cf", 
      loading:      "#CCCCCC",
      hover:        "#388fa1",
      borderRadius: "50%",   
      padding:      "3px"     
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '50px'
    },
    iconFromProject: true,
    flash: ButtonFlashRadial,
    allowPropagate: false,
  }


  export const baseGalleryButton = {
    foreground: {
      active:   "#FFFFFF",
      inactive: "#AAAAAA",
      flash:    "#FFFFFF",
      loading:  "#FFFFFF" 
    },
    background: { 
      padding:      "10px",
      none: true    
    },
    iconPosition: ButtonIconPositionShiftLeft,
    align: ButtonAlignCenter,
    allowPropagate: false,
    loader: {
      fill:"#CCCCCC", 
      wheel: {
        gif:"Spinner-0.4s-310px-ffffff-transparent",
        spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
      }
    }
  }
  
  export const roundCloseButton = {
    ...baseGalleryButton, 
    icon:"rounded-close-x_50", 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    },
  }
  
  export const leftButton = {
    ...baseGalleryButton,
    icon:"rounded-single-chevron-left",
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "40px"
    }
  }
  
  export const rightButton = {
    ...baseGalleryButton,
    icon:"rounded-single-chevron-right",
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "40px"
    }
  }
  
  