
<script>
import { defineComponent, inject, computed } from 'vue'
import Config from '@kit/utils/Config'
import { mergeClassesTheme, mergeStylesTheme } from '@kit/utils/Formats'


//You don't have to set all of them. If you leave one blank, the default won't appear in 
//the class list. This is so that you don't have to list gtH and gtB. gtB alone will do.
const defaultConfig = new Config({
  parent: "sb-v",
  tag: 'div',
  //gtH: 800,
  //gtB: 800,
  //ltD: 80,
  //ltT: 90,
  ltP: 95,
  //ltS: 98,
})

//
//Huge Desktop: H: 2000 and up
//Big Desktop:  B: 1600
//Desktop:      D: 1200
//Med:          T: 960
//Small:        P: 600
//Tiny:         S: 400


export default defineComponent({
  name: "Column",
  props: [ 'theme', 'class', 'tag', 'parent', 'gtH', 'gtB', 'ltD', 'ltT', 'ltP', 'ltS', 'html' ],
  setup(props) {
    const themer = inject("themer")
    const { getProp, setProp } = themer({ props, defaultConfig })

    const class_ = computed(() => {
      const outerClass = props.class ? ` ${props.class}` : ''
      const gtH = getProp('gtH')
      const gtB = getProp('gtB')
      const ltD = getProp('ltD')
      const ltT = getProp('ltT')
      const ltP = getProp('ltP')
      const ltS = getProp('ltS')
      return `${outerClass}${ gtH ? ' gtH-'+gtH : ''}${ gtB ? ' gtB-'+gtB : ''}${ ltD ? ' ltD-'+ltD : ''}${ ltT ? ' ltT-'+ltT : ''}${ ltP ? ' ltP-'+ltP : ''}${ ltS ? ' ltS-'+ltS : ''}`
      //return `gtH-${getProp('gtH')} gtB-${getProp('gtB')} ltD-${getProp('ltD')} ltT-${getProp('ltT')} ltP-${getProp('ltP')} ltS-${getProp('ltS')}${outerClass}`
    })

    return { mergeClassesTheme, mergeStylesTheme, getProp, setProp, class_ }
  },
  components: {}
});
</script>

<style>

.column {
  align-self: center;
}


/* 1200px is the default */
.ltD-50 {
  width:50% !important;
}
.ltD-60 {
  width:60% !important;
}
.ltD-70 {
  width:70% !important;
}
.ltD-80 {
  width:80% !important;
}
.ltD-90 {
  width:90% !important;
}
.ltD-100 {
  width:100% !important;
}

@media (max-width: 960px) {

  .ltT-70 {
    width:70% !important;
  }
  .ltT-80 {
    width:80% !important;
  }
  .ltT-90 {
    width:90% !important;
  }
  .ltT-100 {
    width:100% !important;
  }

}

@media (max-width: 600px) {

  .ltP-80 {
    width:80% !important;
  }
  .ltP-85 {
    width:85% !important;
  }
  .ltP-90 {
    width:90% !important;
  }
  .ltP-95 {
    width:90% !important;
  }
  .ltP-100 {
    width:100% !important;
  }

}

@media (max-width: 400px) {

  .ltS-90 {
    width:90% !important;
  }
  .ltS-92 {
    width:92% !important;
  }
  .ltS94 {
    width:94% !important;
  }
  .ltS-96 {
    width:96% !important;
  }
  .ltS-98 {
    width:96% !important;
  }
  .ltS-100 {
    width:100% !important;
  }

}


@media (min-width: 1600px) {
  .gtB-1200 {
    width:1200px !important;
  }
  .gtB-1000 {
    width:1000px !important;
  }
  .gtB-900 {
    width:900px !important;
  }
  .gtB-800 {
    width:800px !important;
  }
  .gtB-700 {
    width:700px !important;
  }
}
@media (min-width: 2000px) {
  .gtH-1200 {
    width:1200px !important;
  }
  .gtH-1000 {
    width:1000px !important;
  }
  .gtH-900 {
    width:900px !important;
  }
  .gtH-800 {
    width:800px !important;
  }
  .gtH-700 {
    width:700px !important;
  }
}

</style>

<template>

  <component v-if="!html || html.trim() == ''" :is="getProp('tag')" :class="`column sb ${class_}`">
    <slot></slot>
  </component>
  <component v-else :is="getProp('tag')" v-html="html" :class="`column sb ${class_}`">
  </component>


</template>