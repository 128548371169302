export const refundEventMock = {
  "id": "evt_3Pd################cXu",
  "object": "event",
  "api_version": "2024-04-10",
  "created": 1721141837,
  "data": {
      "object": {
          "id": "ch_3P################tLi",
          "object": "charge",
          "amount": 3959,
          "amount_captured": 3959,
          "amount_refunded": 3959,
          "application": null,
          "application_fee": null,
          "application_fee_amount": null,
          "balance_transaction": "txn_3P################pgI",
          "billing_details": {
              "address": {
                  "city": "Arcata",
                  "country": "US",
                  "line1": "1059 14th St",
                  "line2": null,
                  "postal_code": "95521",
                  "state": "CA"
              },
              "email": null,
              "name": "Alexander Lowe",
              "phone": null
          },
          "calculated_statement_descriptor": "CC HARBOR DISTRICT",
          "captured": true,
          "created": 1721141526,
          "currency": "usd",
          "customer": null,
          "description": null,
          "destination": null,
          "dispute": null,
          "disputed": false,
          "failure_balance_transaction": null,
          "failure_code": null,
          "failure_message": null,
          "fraud_details": [],
          "invoice": null,
          "livemode": true,
          "metadata": {
              "end_date": "2024-09-27",
              "daily_rate": "3500",
              "lot_nice_title": "Redwood Harbor Village: Pull-thru",
              "grand_total": "3959",
              "number_of_weeks": "0",
              "total_num_nights": "1",
              "daily_amt": "3500",
              "tax": "358",
              "cchd_space_code": "RHV-91\/93",
              "weekly_rate": "24500",
              "total": "3500",
              "weekly_amt": "0",
              "lot_optional_notes": "This is for pull-thru vehicles.",
              "number_of_days": "1",
              "processing": "101",
              "lot_id": "1324",
              "start_date": "2024-09-26"
          },
          "on_behalf_of": null,
          "order": null,
          "outcome": {
              "network_status": "approved_by_network",
              "reason": null,
              "risk_level": "normal",
              "seller_message": "Payment complete.",
              "type": "authorized"
          },
          "paid": true,
          "payment_intent": "starwars123",
          "payment_method": "pm_1Pd################GiXp",
          "payment_method_details": {
              "card": {
                  "amount_authorized": 3959,
                  "brand": "visa",
                  "checks": {
                      "address_line1_check": "pass",
                      "address_postal_code_check": "pass",
                      "cvc_check": "pass"
                  },
                  "country": "US",
                  "exp_month": 7,
                  "exp_year": 2027,
                  "extended_authorization": {
                      "status": "disabled"
                  },
                  "fingerprint": "n0################PL",
                  "funding": "credit",
                  "incremental_authorization": {
                      "status": "unavailable"
                  },
                  "installments": null,
                  "last4": "6656",
                  "mandate": null,
                  "multicapture": {
                      "status": "unavailable"
                  },
                  "network": "visa",
                  "network_token": {
                      "used": false
                  },
                  "overcapture": {
                      "maximum_amount_capturable": 3959,
                      "status": "unavailable"
                  },
                  "three_d_secure": null,
                  "wallet": null
              },
              "type": "card"
          },
          "radar_options": [],
          "receipt_email": "alex#############.com",
          "receipt_number": "1################7",
          "receipt_url": "https:\/\/pay.stripe.com\/receipts\/payment\/CAcQA################ZTatAYyBj6wtI1R################wpn6hM",
          "refunded": true,
          "review": null,
          "shipping": null,
          "source": null,
          "source_transfer": null,
          "statement_descriptor": null,
          "statement_descriptor_suffix": null,
          "status": "succeeded",
          "transfer_data": null,
          "transfer_group": null
      },
      "previous_attributes": {
          "amount_refunded": 0,
          "receipt_url": "https:\/\/pay.stripe.com\/receipts\/payment\/CAcQAR################Qoy5TatAYyBqpXsHDLXDo################dfD-Gg",
          "refunded": false
      }
  },
  "livemode": true,
  "pending_webhooks": 2,
  "request": {
      "id": "r#############G3P",
      "idempotency_key": "56b9################586771"
  },
  "type": "charge.refunded"
}
