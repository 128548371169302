import { home } from '@project/api/home/v1'
import { product, products, lots, lot } from '@project/api/pay/v1'
// import { section } from '@project/api/sections/v1'
// import { page as contactUsPage } from '@project/api/contact-us/v1'
// import { aboutUs } from '@project/api/about-us/v1'
// import { services } from '@project/api/services/v1'

export default {
  async home() {
    return await home()
  },
  async product(id) {
    return await product(id)
  },
  async products() {
    return await products()
  },
  async lots() {
    return await lots()
  },
  async lot(id) {
    return await lot(id)
  }

  // async flower(slug) {
  //   return await flower(slug)
  // },
  // async family(slug) {
  //   return await family(slug)
  // },
  // async families() {
  //   return await families()
  // },
  // async section(params) {
  //   return await section(params.which)
  // },
  // async contactUs() {
  //   return await contactUsPage()
  // },
  // async aboutUs() {
  //   return await aboutUs()
  // },
  // async services() {
  //   return await services()
  // }
}

