<script>
import { defineComponent, computed, inject, ref, watch, onMounted } from "vue"
import NavMainSearch from "@kit/components/NavMainSearch.vue"
import { searchWidgetMain, navbar } from "@project/themes"
import NavBarBase from "@kit/components/NavBarBase.vue"
import { pathname } from '@kit/utils/Formats'
import IconButton, { ButtonIconActiveHeight } from "@kit/components/IconButton.vue"
import { useStore } from "vuex"
import { useRoute } from 'vue-router'
import "@images/cchd-banner-w-texture.png"
import "@images/CCHDRV-banner.png"
import "@images/CCHD-banner-text_shadow.png"




export default defineComponent({
  name: "NavBar",
  setup() {
    const route = useRoute()
  
    const themer = inject("themer")
    const hydrate = inject("hydrate")

    const { prefetch } = hydrate({
      prefetch:['families']
    })

    const navbarTheme = themer(navbar)
    const searchWidgetMainTheme = themer(searchWidgetMain)

    return { 
      pathname,
      prefetch,
      searchWidgetMain, 
      searchWidgetMainTheme,
      navbarTheme,  
    };
  },
  components: {
    NavMainSearch,
    NavBarBase,
    IconButton
  },
});
</script>

<style>

/*-------------*\
   Navbar CSS 
\*-------------*/


/**************/
/* Containers */
.navbar-outer-container {
  box-shadow: 5px 5px 10px rgba(0,0,0, 0.5);
}

.navbar-inner-container { 
  background-image:url('@images/CCHDRV-banner.png');
  background-repeat: no-repeat;
  background-size:cover;
  background-position:-200px 50%;
} 

.navbar-banner-top-area {
  padding-right:20px;
}

.navbar-mobile-middle-area {
  background-image:url('@images/CCHDRV-banner.png');
  background-repeat: no-repeat;
  background-size:cover;
  background-position:-100px 50%; 
  padding-left:40px;
  padding-right:40px;
}
@media (max-width:680px) {
  .navbar-mobile-middle-area {
    padding-left:20px;
    padding-right:20px;
  }
}
@media (max-width:590px) {
  .navbar-mobile-middle-area {
    padding-left:10px;
    padding-right:10px;
  }
}


/****************/
/* Navbar items */

.navbar-item, .navbar-dropdown-item {
  cursor: pointer;
  padding:10px;
  z-index:2;
  background-color:white;
}
.navbar-item--attop, .navbar-dropdown-item--attop {
  cursor: pointer;
  padding:20px;
  z-index:2;
}

.navbar-item > a.cchdrv-navlink, .navbar-dropdown-item {
  color: var(--dark-blue) !important;
  font-weight: normal !important;
  text-decoration: none;
}
.navbar-item, .navbar-dropdown-item {
  transition: background-color, color 0.5s;
  white-space: nowrap;
}
.navbar-item:hover, .navbar-dropdown-item:hover {
  background-color: var(--dark-blue);
}
.navbar-item:hover > a.cchdrv-navlink, .navbar-dropdown-item:hover {
  color: var(--text-over-dark) !important;
}

.router-link-exact-active.cchdrv-navlink {
  color: var(--text-regular);
  font-weight: bold;
  text-decoration: none; 
}


/****************/
/* Navbar items */

.navbar-subitems > a, 
.navbar-subitems > a.router-link-exact-active {
  padding:10px 15px 10px 15px;
}

.navbar-subitems--attop > a, 
.navbar-subitems--attop > a.router-link-exact-active {
  padding:10px 15px 10px 15px;
}

.navbar-subitems > .cchdrv-navlink {
  color: var(--text-over-dark);
  font-weight: normal;
  text-decoration: none; 
  background-color:var(--blue);
}
.navbar-subitems > .cchdrv-navlink:hover {
  background-color: var(--dark-blue)
}
.navbar-subitems > .router-link-exact-active.cchdrv-navlink {
  color: var(--text-over-dark);
  font-weight: bold;
  text-decoration: none; 
  background-color:var(--blue);
}

.navbar-subitems > .router-link-exact-active.cchdrv-navlink:hover {
  background-color:var(--blue);
}


/********************/
/* Hamburger Button */
@media (max-width:500px) {
  .hamburger-button {
    margin-top:10px; 
    margin-bottom:10px;
    margin-right:20px; 
    margin-left:15px;
    border-top:4px solid #555555; 
    border-bottom:4px solid #555555; 
    width: 50px;
    height:30px;
    cursor:pointer;
  }
}


/********/
/* Logo */

.navbar-logo-wrapper {
  width: 60px;
  /* height: 100%; */
  height:70px;
  /* background:red; */
  padding:5px;
}
.navbar-logo-wrapper--attop {
  width:160px;
  /* height:100%; */
  height:230px;
  /* background:green; */
  padding:20px;
}

@media (max-width:960px) {
  .navbar-logo-wrapper--attop {
  width:60px;
  padding:5px;
  /* height:100%; */
  /* height:230px; */
  /* background:green; */
  /* padding:20px; */
  }
}

.cchdrv-right-logo {
  height:150px; 
  width:auto; 
  object-fit:contain; 
  object-position:center;
  transform: translateY(30px);
}

.cchdrv-banner-text {
  height:60px; 
  width:auto; 
  object-fit:contain; 
  object-position:center;
}

@media (max-width:1160px) {
  .cchdrv-banner-text {
    height:50px;
  }
  .cchdrv-right-logo {
    transform: translateY(0px);
  }
}
@media (max-width:1000px) {
  .cchdrv-banner-text {
    height:44px;
  }
}

.cchdrv-right-logo-mobile {
  height:55px; 
  width:auto; 
  object-fit:contain; 
  object-position:center;
}

.cchdrv-banner-text-mobile {
  height:30px; 
  width:auto; 
  object-fit:contain; 
  object-position:center;
}
@media (max-width:680px) {
  .cchdrv-banner-text-mobile {
    height:25px;
  }
}
@media (max-width:590px) {
  .cchdrv-banner-text-mobile {
    height:22px;
  }
}
@media (max-width:550px) {
  .cchdrv-banner-text-mobile {
    height:19px;
  }
}
@media (max-width:470px) {
  .cchdrv-banner-text-mobile {
    height:0px;
    opacity:0;
  }
}




/**********************/
/* Extra banner areas */

.navbar-banner-right-area {
  width: 100px !important;
}
.navbar-banner-right-area--attop {
  width: 200px !important;
}

.navbar-banner-top-area > img {
  display: none;
}
.navbar-banner-top-area--attop > img {
  display: initial;
}
.navbar-banner-nav-area {
  /* background:red; */
}
.navbar-banner-right-area {
  /* background:orange; */
  width:200px;
  padding:5px;
}

.banner-background {
  height:230px;
  width:auto;
  object-fit:cover;
}


</style>

<template>
    <NavBarBase :theme="navbarTheme">

      <template v-slot:nav-inner-container>
        <!-- <img src="@images/CCHDRV-banner.png" class="sb-spread banner-background"> -->
      </template>
      
      <template v-slot:nav-area="{ navItemClasses, dropDownNavItemClasses, navSubItemClasses }">
          
        <div :class="navItemClasses">
          <router-link class="sb cchdrv-navlink" to="/">Home</router-link>
        </div>
  
        <div :class="dropDownNavItemClasses">
          About The Harbor
          <div :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" aria-label="About The Harbor">
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/history" style="white-space:nowrap;">History</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/info" style="white-space:nowrap;">Info</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/friends-of-the-harbor" style="white-space:nowrap;">Friends of the Harbor</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/key-documents" style="white-space:nowrap;">Key Documents</a>
          </div>
        </div>

        <div :class="dropDownNavItemClasses">
          Harbor Commission
          <div :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" aria-label="Harbor Commission">
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/board-of-commissioners" style="white-space:nowrap;">Board Commissioners</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/crescent-city-harbor-district-board-of-commisioner-meetings" style="white-space:nowrap;">Board Meetings</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/archived-agendas" style="white-space:nowrap;">Agendas</a>
          </div>
        </div>

    
        <div :class="dropDownNavItemClasses">
          Boaters
          <div :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" aria-label="Boaters">
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/moorage-reservations" style="white-space:nowrap;">Moorage Reservations</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/marina-brochure-and-boater-maps" style="white-space:nowrap;">Marina Maps</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/services-and-fees" style="white-space:nowrap;">Services and Fees</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/policies" style="white-space:nowrap;">Policies</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/legal-notices" style="white-space:nowrap;">Legal Notices</a>
          </div>
        </div>

        <div :class="dropDownNavItemClasses">
          Things To Do
          <div :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" aria-label="Things To Do">
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/business-index" style="white-space:nowrap;">Businesses</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/event-calendar" style="white-space:nowrap;">Events</a>
          </div>
        </div>

        <div :class="dropDownNavItemClasses">
          Updates
          <div :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" aria-label="Updates">
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/news" style="white-space:nowrap;">News</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/archived-agendas" style="white-space:nowrap;">Board Meeting Agendas</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/request-for-proposals" style="white-space:nowrap;">Requests for Proposals</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/current-openings" style="white-space:nowrap;">Employement Opportunities</a>
            <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/lease-opportunities" style="white-space:nowrap;">Lease Opportunities</a>
          </div>
        </div>


        <div :class="navItemClasses">
          <a class="sb cchdrv-navlink" href="https://www.ccharbor.com/contact-us">Contact Us</a>
        </div>

          <!-- <NavMainSearch :theme="searchWidgetMainTheme" /> -->
          
      </template>

      <template v-slot:right-area>
      
        <img alt="CCHD Bayside Sign Logo" style="height:100%; width:auto; object-fit:contain; object-position:center;" :src="pathname('@images/cchd-banner-w-texture.png')">
      
      </template>

      <template v-slot:top-area>

        <img alt="Crescent City Harbor District Sign" class="cchdrv-banner-text" :src="pathname('@images/CCHD-banner-text_shadow.png')">
        <img alt="Crescent City Harbor District Logo" class="cchdrv-right-logo" :src="pathname('@images/cchd-banner-w-texture.png')">
      
      </template>

      <template v-slot:nav-mobile-middle-area>

        <img alt="Crescent City Harbor District Sign" class="sb cchdrv-banner-text-mobile" :src="pathname('@images/CCHD-banner-text_shadow.png')">
        <img alt="Crescent City Harbor District Logo" class="sb cchdrv-right-logo-mobile" :src="pathname('@images/cchd-banner-w-texture.png')">
      
      
      </template>

    </NavBarBase>

</template>
