import { roundCloseButtonBlue, roundCloseButtonRed } from '@project/themes'
import { ModalPositionCenter} from '@kit/components/Modal.vue'

export const cchdrvModal = {
  windowAutoPosition: true,
  position: ModalPositionCenter,
  windowClasses: "cchdrv-modal-window",
  backgroundClasses: "cchdrv-modal-background",
  closeButton: roundCloseButtonBlue,
  innerClasses: { override:"sb sb-v sb-content-strict-height sb-g10 sb-greedy-1" },
  windowStyles: { append: "background:#FFFFFF; box-shadow:6px 6px 5px #999999; border-radius:4px; border-left:2px solid #DDDDDD; border-top:2px solid #DDDDDD;" },
}


export const cchdrvErrorModal = {
  ...cchdrvModal,
  closeButton: roundCloseButtonRed,
}
