


export const cchdrvImgTheme = {
  loader: {
    wheel: "DoubleRing",
    fromProject: true,
  },

  //Works. img
  // display: 'auto',
  // width:'100%',

  //Works
  // display: 'fit',
  // fit:'contain',
  // position:'top',
  // width:'100%',
  // aspect:1,

  //Works
  // display:'region',
  // region: '70 0 0',
  // width:'100%',
  // aspect:1.5,
  // tag: 'div',

  ldisplay:'fit',
  lfit:'contain',
  laspect:1,
  lgutter:30,
  lregion:null
}