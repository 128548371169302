//Cancel out the annoying formats for the phone-number
//https://stackoverflow.com/questions/3736807/how-do-i-remove-the-blue-styling-of-telephone-numbers-on-iphone-ios
export const meta = (meta) => {
  meta.addMetaItem([
    //<meta name="format-detection" content="telephone=no">
    { name:'format-detection', content: 'telephone=no' },
  ])
  meta.addAppAttribute([
    { name:"style", value:"height:auto"}
  ])
}

export const extraJS = null

//We need to include this. There should be a separate one for 
//https://csswizardry.com/2020/05/the-fastest-google-fonts/

export const externalCSS = [
  {type:'webfont', href:'https://fonts.googleapis.com/css?family=Raleway:300,400,700'},
  {type:'webfont', href:'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700'},
  {type:'webfont', href:'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500'},
]

