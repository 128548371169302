import VueSmoothScroll from 'vue3-smooth-scroll'
import PrimeVue from 'primevue/config'
//import VueGoogleMaps from '@fawmi/vue-google-maps'
import { getEnv } from '@kit/utils/EnvironmentHelper'


export const modifyProvideApp = () => {
  return null
}

export const modifyApp = (app, router) => {

  // app.use(VueGoogleMaps, {
  //   load: {
  //     key: getEnv('GOOGLE_MAPS_NORMAL_API_KEY')
  //   }  
  // })

  app.use(PrimeVue, { ripple:true })

  app.use(VueSmoothScroll, {
    updateHistory: false
  })

}
