
import { pathname } from '@kit/utils/Formats'
//import "@images/CCRVHD-logo_bayside.png"
import "@images/CCHDRV-logo-Bayside-RHV-vertical.png"

export const navbar = {

  //optional. if it will shrink on scroll
  scrollShrink: true, //false,

  //shrinkColor:"rgba(255,255,255,1)",
  backgroundColor:"#FFFFFF",
  initialColor:"rgba(255,255,255,1)",
  animateBackgroundChange: false,
  backgroundProperty:'background-color',

  //the logo and aria text
  //logoWrapperClasses:"sb-explicit",
  logoSrc:pathname("@images/CCHDRV-logo-Bayside-RHV-vertical.png"),
  logoAriaAlt:"CCHD Logo",

  //required
  height: "230px",
  shrinkHeight: "70px",
  mobileHeight: "70px",


  padding: "15px",
  shrinkPadding: "5px",

  //optional. The top gap
  topGap: "0px",

  mobileMiddleAreaClasses: 'sb-content-space-between sb-align-cv sb-alt2',

  desktopLayout: 'banner',
  banner: {
    topAreaClasses:'sb-greedy-1 sb-alt2 sb-content-space-between sb-align-cv',
    hasRightArea: false
  }
    
}

export const sideDrawer = {
  topNavHeight:"70px",
}