import { roundCloseButtonBlue } from '@project/themes'

export const ccDrawer = {

  suspendScrolling: true,
  
  backgroundStyle: "opacity:0.8; background-color:#FFFFFF;",
  
  windowStyles: "background:#FFFFFF; box-shadow:6px 6px 5px #999999; border-left:2px solid #DDDDDD;",

  vw: 80,
  vh: 80,
  mvw: 80,
  mvh: 80,

  closeButton: roundCloseButtonBlue
}