<script>
import { defineComponent, computed } from "vue";
import SidebarRouteLink from "@kit/components/SidebarRouteLink.vue";
import NavMainSearch from "@kit/components/NavMainSearch.vue";
import SideNavDrawerBase from "@kit/components/SideNavDrawerBase.vue";
import { searchWidgetMobile, sideDrawer  } from "@project/themes";
import IconButton from '@kit/components/IconButton.vue'
import { useStore } from "vuex"
import { getCurrentYear } from '@kit/utils/Formats'

export default defineComponent({
  name: "SizeNavDrawer",
  setup() {
    const store = useStore()

    const userAccount = computed(() => {
      return store.state.account
    })

    return { searchWidgetMobile, sideDrawer, userAccount,  getCurrentYear };

  },
  components: {
    SideNavDrawerBase,
    SidebarRouteLink,
    NavMainSearch,
    IconButton
  },
});
</script>

<style>

.side-nav-drawer-outer {
  background: rgb(65,82,43);
  background: linear-gradient(70deg, rgb(39, 44, 91) 0%, rgb(58, 68, 145)47%, rgb(32, 77, 123) 100%);
  opacity: 1;
}


.cchdrv-sidenav-item > a {
  color:white;
  font-size:1.5rem !important;
}
.ics-sidenav-copyright {
  color:white;
  font-size:1.2rem;
}

.cchdrv-sidenav-subitems > span {
  color:white;
  font-size:1.5rem !important; 
  font-weight: bold;
}

.cchdrv-sidenav-subitems > a {
  color:white;
  font-size:1.2rem !important; 
}

.indent-1 {
  margin-left:40px !important;
}

</style>

<template>
  <SideNavDrawerBase :theme="sideDrawer">
    <template #nav-items="{ active }">

        <div class="sb cchdrv-sidenav-item">
          <a href="/">Home</a>
        </div>
  
        <div class="sb sb-v cchdrv-sidenav-subitems" aria-role="menu" aria-orientation="vertical" aria-label="About The Harbor">
          <span class="sb sb-text">About The Harbor</span>

          <a class="sb indent-1" href="https://www.ccharbor.com/history" style="white-space:nowrap;">History</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/info" style="white-space:nowrap;">Info</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/friends-of-the-harbor" style="white-space:nowrap;">Friends of the Harbor</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/key-documents" style="white-space:nowrap;">Key Documents</a>
     
        </div>

        <div class="sb sb-v cchdrv-sidenav-subitems" aria-role="menu" aria-orientation="vertical" aria-label="Harbor Commission">
          <span class="sb sb-text">Harbor Commission</span>

          <a class="sb indent-1" href="https://www.ccharbor.com/board-of-commissioners" style="white-space:nowrap;">Board Commissioners</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/crescent-city-harbor-district-board-of-commisioner-meetings" style="white-space:nowrap;">Board Meetings</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/archived-agendas" style="white-space:nowrap;">Agendas</a>
 
        </div>

    
        <div class="sb sb-v cchdrv-sidenav-subitems" aria-role="menu" aria-orientation="vertical" aria-label="Boaters">
          <span class="sb sb-text">Boaters</span>

            <a class="sb indent-1" href="https://www.ccharbor.com/moorage-reservations" style="white-space:nowrap;">Moorage Reservations</a>
            <a class="sb indent-1" href="https://www.ccharbor.com/marina-brochure-and-boater-maps" style="white-space:nowrap;">Marina Maps</a>
            <a class="sb indent-1" href="https://www.ccharbor.com/services-and-fees" style="white-space:nowrap;">Services and Fees</a>
            <a class="sb indent-1" href="https://www.ccharbor.com/policies" style="white-space:nowrap;">Policies</a>
            <a class="sb indent-1" href="https://www.ccharbor.com/legal-notices" style="white-space:nowrap;">Legal Notices</a>

        </div>

        <div class="sb sb-v cchdrv-sidenav-subitems" aria-role="menu" aria-orientation="vertical" aria-label="Things To Do">
          <span class="sb sb-text">Things To Do</span>
          <a class="sb indent-1" href="https://www.ccharbor.com/business-index" style="white-space:nowrap;">Businesses</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/event-calendar" style="white-space:nowrap;">Events</a>
 
        </div>

        <div class="sb sb-v cchdrv-sidenav-subitems" aria-role="menu" aria-orientation="vertical" aria-label="Updates">
          <span class="sb sb-text">Updates</span>
          <a class="sb indent-1" href="https://www.ccharbor.com/news" style="white-space:nowrap;">News</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/archived-agendas" style="white-space:nowrap;">Board Meeting Agendas</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/request-for-proposals" style="white-space:nowrap;">Requests for Proposals</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/current-openings" style="white-space:nowrap;">Employement Opportunities</a>
          <a class="sb indent-1" href="https://www.ccharbor.com/lease-opportunities" style="white-space:nowrap;">Lease Opportunities</a>

        </div>


        <div class="sb cchdrv-sidenav-item">
          <a href="https://www.ccharbor.com/contact-us">Contact Us</a>

          <a class="sb" href="https://www.ccharbor.com/contact-us">Contact Us</a>
        </div>


      <!-- <NavMainSearch :theme="searchWidgetMobile" :active="active" /> -->
      
      <div class="sb sb-text ics-sidenav-copyright">
        © {{ getCurrentYear() }} Crescent City Harbor District
      </div>

    </template>
  </SideNavDrawerBase>
</template>
