
<script>
import { defineComponent, computed, onMounted } from 'vue'
import { footerButton } from '@project/themes'
import IconButton from '@kit/components/IconButton.vue'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { getCurrentYear } from '@kit/utils/Formats'
import ParallaxImage from '@kit/components/ParallaxImage.vue'
import Column from '@kit/components/Column.vue'

export default defineComponent({
  name: "Footer",
  setup() {
    //const store = useStore()
    //const locations = computed(() => store.state.locations)
    const locations = computed(() => [])

    onMounted(() => {})

    return { 
      locations, 
      footerButton, 
      inBrowser,
      getCurrentYear
    }

  },
  components: {
    IconButton,
    ParallaxImage,
    Column
  }
});
</script>



<style scoped>
  .footer {
    background:#207CA7;
    padding:30px 80px 70px 80px;
  }
  @media (max-width:570px) {
    .footer {
      padding:30px 40px 70px 40px;
    }
  }
  .footer > .right-panel > li > a {
    color: white;
    font-weight: normal;
    font-size:0.8rem;
  }
  .footer > .left-panel > li {
    color: white;
    font-weight: normal;
    font-size:0.8rem;
  }
</style>

<template>

  <Column tag="footer" class="sb sb-h sb-ltm-h2v sb-gr sb-g10 sb-content-space-between footer" gtB="1200" ltD="100" ltT="100" ltP="100" style="margin-top:50px;">

    <ul class="sb sb-v left-panel">
      <li class="sb">COPYRIGHT © {{ getCurrentYear() }} CRESCENT CITY HARBOR DISTRICT</li>
      <li class="sb">101 CITIZENS DOCK ROAD, CRESCENT CITY CA</li>
      <li class="sb">TELEPHONE (707) 464-6174</li>
    </ul>

    <ul class="sb sb-v right-panel">
      <li class="sb"><a href="https://www.ccharbor.com/privacy-policy">Privacy Policy</a></li>
      <li class="sb"><a href="https://www.ccharbor.com/contact-us">Contact Us</a></li>
      <li class="sb"><a href="https://www.ccharbor.com/transparency.html">District Transparency</a></li>
    </ul>

  </Column>

</template>